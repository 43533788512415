<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- image -->
                        <!-- <span v-if="image">
                            <picture>
                                <source class="product_image"  :srcset="val.content.webpImage" type="image/webp">
                                <img class="product_image"  :src="val.content.originalImage" alt="Card image cap">
                            </picture>
                        </span> -->
                        <img class="company" src="@/assets/images/import/default-image.jpg" alt="">
                        <!-- description -->
                        <h2 class="text-center mt-2">{{name}}</h2>
                        <p class="text-center text-muted">{{type}}</p>
                        <div class="row mt-4">
                            <!-- 1ère rangée -->
                            <div class="col-md-6 col-sm-12">
                                <h4>Contact</h4>
                                <span>
                                    <h6 class="text-facebook">Email</h6>
                                    <p class="text-muted">{{email}}</p>
                                </span>
                                <span>
                                    <h6 class="text-facebook">Numéro téléphone</h6>
                                    <p class="text-muted">{{phone}}</p>
                                </span>
                                <h4 class="mt-2">Informations légales et juridiques</h4>
                                <span class="mt-3">
                                    <h6 class="text-facebook">SIRET</h6>
                                    <p class="text-muted">{{siret}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">SIREN</h6>
                                    <p class="text-muted">{{siren}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">N° TVA</h6>
                                    <p class="text-muted">{{tva}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">RCS</h6>
                                    <p class="text-muted">{{rcs}}</p>
                                </span>
                            </div>
                            <!-- 2ème rangée -->
                            <div class="col-md-6 col-sm-12">
                                <h4>Adresse</h4>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Adresse 1</h6>
                                    <p class="text-muted">{{line1}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Adresse secondaire</h6>
                                    <p class="text-muted">{{line2}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Ville</h6>
                                    <p class="text-muted">{{city}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Code postal</h6>
                                    <p class="text-muted">{{postal_code}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Département</h6>
                                    <p class="text-muted">{{state}}</p>
                                </span>
                                <span class="mt-3">
                                    <h6 class="text-facebook">Pays</h6>
                                    <p class="text-muted">{{country}}</p>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <span class="d-flex justify-content-between">
                                    <h4>Heure d'ouverture</h4>
                                    <button class="btn btn-secondary" @click="$router.push({name:'closing-dates'})">Modifier les heures</button>
                                </span>
                                <span>
                                    <h6 class="text-facebook">Lundi</h6>
                                    <p class="text-muted">08:00 - 18:00</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end m-2">
                        <button class="btn btn-link mx-2" @click="company()">Retour</button>
                        <button class="btn btn-primary" @click="editCompagny()">Modifier</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
export default {
    name:"companyDetails",
    data(){
        return{
            ulid:'',
            name:'',
            type:'',
            email:'',
            phone:'',
            line1:'',
            line2:'',
            city:'',
            postal_code:'',
            state:'',
            country:'',
            siret:'',
            siren:'',
            tva:'',
            rcs:'',
            image:'',
            ploader:false
        }
    },
    methods:{
        getCompany(){
            this.ploader= true
            axios.get("companies/"+this.ulid)
            .then(
                result =>{
                    console.log({result});
                this.name= result.data.name
                this.type= result.data.type
                this.line1= result.data.address.line1
                this.line2= result.data.address.line2
                this.city= result.data.address.city
                this.postal_code= result.data.address.postal_code
                this.state= result.data.address.state
                this.country= result.data.address.country
                this.siren= result.data.infos.siren
                this.siret= result.data.infos.siret
                this.tva= result.data.infos.tva
                this.rcs= result.data.infos.rcs
                this.email= result.data.email
                this.phone= result.data.tel
                this.image = result.data.image
                this.ploader= false
                }
            )
            .catch(
                errgetCompany => {
                console.error({errgetCompany})
                return
                }
            )
        },
        editCompany(){
            this.$router.push({
                name:"editCompany",
                params:{
                    ulid:this.ulid
                }
            })
        },
        company(){
            this.$router.push({
                name:"company"
            })
        }
    },
    mounted(){
        this.ulid = this.$route.params.ulid
        this.getCompany()

    }
}
</script>
<style scoped>
.company {
    width:100%;
    height:50vh;
    object-fit: cover;
}
</style>